@import url("../node_modules/vis-network/dist/dist/vis-network.css");
@import url("../node_modules/normalize.css/normalize.css");

html {
  height: 100%;
}

body {
  height: 100%;
}

#mynetwork {
  height: 100%;
}
